import React from 'react';
import PropTypes from 'prop-types';
import TextSection from './TextSection';
import CenteredImage from './CenteredImage';

function ImageXText(data) {
  const { id, image, text } = data;
  return (
    <div id={id}>
      {CenteredImage(image)}
      <TextSection
        backgroundColor={text.backgroundColor}
        divider={text.divider}
        layout={text.layout}
        className={text.className}
        body={text.body}
      />
    </div>
  );
}

ImageXText.propTypes = {
  data: PropTypes.shape({})
};

ImageXText.defaultProps = {
  data: PropTypes.shape({})
};

export default ImageXText;
