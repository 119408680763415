/* eslint-disable no-restricted-globals  */
/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import SectionHeader from './SectionHeader';
import BaseButton from './buttons/BaseButton';
import cards from './case-studies-data';
import CardBasic from './cards/CardBasic';
import { theme } from '../Theme';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};
  h2 {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 16px;
    letter-spacing: 0.48px;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const runtime = typeof document === 'object';
let evt;

function Cards({ data }) {
  const { card } = data;
  return (
    <CardBasic
      key={pmUuid()}
      roundedCorners={!card.roundedCorners}
      col="col-12 col-sm-6 col-lg-4"
      media={card.media}
      header={card.header}
      subtitle={card.subtitle}
      paragraph={card.paragraph}
      body={card.body}
      link={card.link}
      hover={card.hover}
      clickableCardLink={card.clickableCardLink}
      href={card.link.src}
      target={card.link.target}
      ratio={card.ratio}
      fullWidthImage={false}
      className="mb-5"
      event={evt && `${evt.itemClicked}:${card.id}`}
      eventProp={
        evt &&
        `{"cta_link_to":"${card.link.src}","cta_style":"primary","cta_text":"${card.subtitle.text}","cta_type":"card"}`
      }
    />
  );
}

function DynamicCustomerCaseStudyCards(props) {
  const [loadCards, setCards] = useState([]);
  const { data } = props;
  const { id, featured, title, body, ctaButton, link, divider } = data;

  useEffect(() => {
    (function filterCards() {
      // return static cards if featured array is defined and id is undefined
      let results = [];
      if (featured && featured.length === 3 && !id) {
        results = cards.filter((card) => featured.includes(card.id));
        setCards(results);
      } else {
        // return three random cards and exclude matching card by id
        const filteredById = cards.filter((card) => card.id !== id);
        const shuffled = filteredById.sort(() => 0.5 - Math.random());
        setCards(shuffled.slice(0, 3));
      }
    })();

    if (runtime && data.event) {
      window.event = data.event;
    }

    if (runtime && window.event) {
      document
        .getElementById('CaseStudiesSection')
        .setAttribute('data-event-view', window.event.viewed);

      evt = window.event;
    }
  }, [data.event, featured, id]);

  const bodyText = [
    '<p class="subtitle">See a wide selection of detailed use cases from nearly every industry on the <a href="/case-studies/">Postman case studies page</a>.</p>'
  ];
  return (
    <>
      <SectionStyles id="CaseStudiesSection">
        <div className="container">
          <div className="row">
            <SectionHeader
              title={title || 'Case studies'}
              body={body || bodyText}
              link={link}
              textAlign="left"
              bottomMargin="40px"
            />
          </div>
          <div className="row justify-content-center">
            {loadCards.map((cardInfo, index) => {
              const card = { ...cardInfo, index };
              return <Cards data={{ card }} key={pmUuid()} />;
            })}
          </div>
          <div className="row justify-content-center">
            {ctaButton &&
              Array.isArray(ctaButton) &&
              ctaButton.map((cta) => (
                <div className="mb-4 ml-2" key={pmUuid()}>
                  <BaseButton
                    buttonType={cta.buttonType}
                    as="a"
                    src={cta.src}
                    target={cta.target}
                    id={cta.id}
                    gaCategory={cta.gaCategory}
                    gaLabel={cta.gaLabel}
                    className={cta.type === 'inline' ? 'pl-0' : null}
                    icon={cta.icon}
                    dataTest={cta.id}
                  >
                    {cta.text}
                  </BaseButton>
                </div>
              ))}
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

DynamicCustomerCaseStudyCards.propTypes = {
  data: PropTypes.shape({}).isRequired
};

function CaseStudyThreeAcross(props) {
  return <DynamicCustomerCaseStudyCards data={props} />;
}

// Static Version
// Reference file: Use "id" in case-studies-data.jsx
// {
//   "type": "caseStudyThreeAcrossV6",
//   "id": "",
//   "featured": ["Shopify", "OVO", "Twitter"]
// }

// Dynamic version with the option to exclude a case study
// {
//   "type": "caseStudyThreeAcrossV6",
//   "id": "Twitter",
//   "featured": ["Shopify", "OVO", "WhatsApp Business"]
// }

export default CaseStudyThreeAcross;
