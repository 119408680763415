import React from 'react';
import { Divider } from 'aether-marketing';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: rgb(249, 249, 249); */
  h3 {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
    line-height: 1.3;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  ul {
    padding-left: 0px;
  }
`;

const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0px auto 0px auto;
`;

function JpHeroStacked({
  backgroundColor,
  title,
  body,
  bodyAlignment,
  media,
  divider,
  listTitle,
  listBody
}) {
  return (
    <div>
      <SectionStyles backgroundColor={backgroundColor}>
        <div className="container">
          <div className="row">
            {/* TOP - IMAGE */}
            <div className="col-12 text-center">
              {media && media.src && (
                <img
                  alt={media.alt}
                  className={`mb-5 img-fluid  ${
                    media.class ? ` ${media.class}` : ''
                  }`}
                  height={media.height ? `${media.height}` : '100%'}
                  src={media.src}
                  width={media.width ? `${media.width}` : '100%'}
                />
              )}
              {title && (
                <h1 dangerouslySetInnerHTML={{ __html: title.join('') }} />
              )}
            </div>
            <div className="col-12 col-md-8 offset-md-2">
              {body && (
                <div
                  className={`${bodyAlignment ? ` text-${bodyAlignment}` : ''}`}
                  dangerouslySetInnerHTML={{ __html: body.join('') }}
                />
              )}
            </div>
          </div>
          <div className="col-8 mx-auto">
            {listTitle && (
              <>
                <h2
                  className="text-center mt-5 mb-3"
                  dangerouslySetInnerHTML={{ __html: listTitle.join('') }}
                />
                <OrangeLine className="mb-5" />
              </>
            )}
            {listBody && (
              <div dangerouslySetInnerHTML={{ __html: listBody.join('') }} />
            )}
          </div>
        </div>
      </SectionStyles>
      {(divider !== false && <Divider fullWidth />) || null}
    </div>
  );
}

JpHeroStacked.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
  bodyAlignment: PropTypes.string.isRequired,
  listTitle: PropTypes.arrayOf(PropTypes.string),
  listBody: PropTypes.arrayOf(PropTypes.string),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    class: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
  }),
  divider: PropTypes.bool
};

JpHeroStacked.defaultProps = {
  backgroundColor: null,
  divider: null,
  media: {
    src: null,
    alt: null,
    class: null,
    height: null,
    width: null
  },
  listTitle: null,
  listBody: null
};

export default JpHeroStacked;
