//  P tag for sections
import React from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import { theme } from '../Theme';
import BaseButton from './buttons/BaseButton';
import { triggerGA, getAnchorId } from '../../utils/functions';
import BaseLink from './links/BaseLink';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const ContentStyles = styled.div`
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
  h3 {
    font-size: 28px;
    font-weight: 600;
  }
`;

function TextSection({
  title,
  subtitle,
  body,
  button,
  link,
  backgroundColor,
  paddingBottom,
  paddingTop,
  className,
  divider,
  logo,
  layout,
  SideXSideText // this prop is for inside sideXSide, takes out container and stuff
}) {
  let colText;

  switch (layout) {
    case 'center':
      colText = 'offset-md-1';
      break;
    case 'left':
      colText = 'offset-md-0';
      break;
    case 'right':
      colText = 'offset-md-4';
      break;
    default:
      // everything else defaults to layout Center
      colText = 'offset-md-2';
  }

  return (
    <>
      <SectionStyles
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        {SideXSideText === true ? (
          <>
            <ContentStyles>
              {logo && (
                <img className="w-100 mb-4" src={logo.src} alt={logo.alt} />
              )}
              {title && <h2 id={`${getAnchorId(title)}`}>{title}</h2>}
              {subtitle && <p className="subtitle mb-4">{subtitle}</p>}
              {body && (
                <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
              )}
            </ContentStyles>
            {button &&
              Array.isArray(button) &&
              button.map((cta) => (
                <div className="mb-4 mr-3 d-lg-inline" key={pmUuid()}>
                  <BaseButton
                    buttonType={cta.buttonType}
                    as="a"
                    src={cta.src}
                    target={cta.target}
                    id={cta.id}
                    gaCategory={cta.gaCategory}
                    gaLabel={cta.gaLabel}
                    className={cta.type === 'inline' ? 'pl-0' : null}
                    dataTest={cta.id}
                    onClick={() => {
                      triggerGA(cta[0].category, cta[0].label);
                    }}
                  >
                    {cta.text}
                  </BaseButton>
                </div>
              ))}
            {link &&
              Array.isArray(link) &&
              link.map((cta) => (
                <p key={pmUuid()}>
                  <BaseLink
                    linkType={cta.type}
                    src={cta.src}
                    className={cta.className && cta.className}
                    alternative={cta.alternative ? cta.alternative : false}
                    target={cta.target ? cta.target : 'same-tab'}
                    gaCategory={cta.gaCategory && cta.gaCategory}
                    gaLabel={cta.gaLabel && cta.gaLabel}
                    id={cta.id && cta.id}
                    ariaLabel={cta.ariaLabel && cta.ariaLabel}
                    dataTest={cta.id}
                  >
                    {cta.text}
                  </BaseLink>
                </p>
              ))}
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className={`${colText} ${className}`}>
                <ContentStyles>
                  {title && <h2>{title}</h2>}
                  {subtitle && <p className="subtitle mb-4">{subtitle}</p>}
                  {body && (
                    <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
                  )}
                </ContentStyles>
                {button &&
                  Array.isArray(button) &&
                  button.map((cta) => (
                    <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
                      <BaseButton
                        buttonType={cta.buttonType}
                        as="a"
                        src={cta.src}
                        target={cta.target}
                        id={cta.id}
                        gaCategory={cta.gaCategory}
                        gaLabel={cta.gaLabel}
                        className={cta.type === 'inline' ? 'pl-0' : null}
                        dataTest={cta.id}
                        onClick={() => {
                          triggerGA(cta[0].category, cta[0].label);
                        }}
                      >
                        {cta.text}
                      </BaseButton>
                    </div>
                  ))}
                {link &&
                  Array.isArray(link) &&
                  link.map((cta) => (
                    <p key={pmUuid()}>
                      <BaseLink
                        linkType={cta.type}
                        src={cta.src}
                        className={cta.className && cta.className}
                        alternative={cta.alternative ? cta.alternative : false}
                        target={cta.target ? cta.target : 'same-tab'}
                        gaCategory={cta.gaCategory && cta.gaCategory}
                        gaLabel={cta.gaLabel && cta.gaLabel}
                        id={cta.id && cta.id}
                        ariaLabel={cta.ariaLabel && cta.ariaLabel}
                        dataTest={cta.id}
                      >
                        {cta.text}
                      </BaseLink>
                    </p>
                  ))}
              </div>
            </div>
          </div>
        )}
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

export default TextSection;

TextSection.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cta: PropTypes.shape({
          buttonType: PropTypes.string,
          className: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          icon: PropTypes.string,
          id: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ]),
  link: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cta: PropTypes.shape({
          linkType: PropTypes.string,
          text: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          id: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          icon: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ]),
  backgroundColor: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  divider: PropTypes.bool,
  SideXSideText: PropTypes.bool
};

TextSection.defaultProps = {
  layout: '',
  title: '',
  className: null,
  subtitle: '',
  body: null,
  button: {
    cta: {
      id: null,
      gaCategory: null,
      gaLabel: null,
      icon: null
    }
  },
  link: {
    cta: {
      id: null,
      gaCategory: null,
      gaLabel: null,
      icon: null
    }
  },
  logo: null,
  backgroundColor: '',
  paddingBottom: '',
  paddingTop: '',
  divider: false,
  SideXSideText: false
};
