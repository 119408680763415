import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseButton from './buttons/BaseButton';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) => props.backgroundColor || 'white'};

  h3 {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
    line-height: 1.3;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

function relStringGenerator(target) {
  if (target === 'new-tab-postman' || target === 'external-postman') {
    return 'noopener';
  }
  if (target === 'new-tab-external-company' || target === 'external-other') {
    return 'noopener noreferrer';
  }
  if (
    target === 'new-tab-external-nofollow' ||
    target === 'external-nofollow'
  ) {
    return 'noopener noreferrer nofollow';
  }

  return null;
}
// Helper function for target attribute in link or button
function targetStringGenerator(target) {
  const newTabs = [
    'new-tab-postman',
    'new-tab-postman',
    'new-tab-external-company',
    'external-other',
    'new-tab-external-nofollow',
    'new-tab-external-nofollow'
  ];

  for (let i = 0; i < newTabs.length; i += 1) {
    if (newTabs[i] === target) {
      return '_blank';
    }
    if (target === 'same-tab') {
      return '_self';
    }
  }

  return '_self';
}
function CallOut({ body, link, anchorId, backgroundColor, title, textAlign }) {
  const textAlignToggle = textAlign === 'center' ? ' offset-md-2' : '';
  return (
    <SectionStyles
      id={anchorId}
      style={{ backgroundColor: backgroundColor || 'white' }}
    >
      <div className="container">
        <div className="row">
          <div className={`col-sm-12 col-md-8 ${textAlignToggle}`}>
            {title && <h2>{title}</h2>}
            <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
            {link && (
              <BaseButton
                buttonType={link.buttonType}
                src={link.src}
                href={link.src}
                id={link.id}
                rel={relStringGenerator(link.target)}
                target={targetStringGenerator(link.target)}
                as="a"
                aria-label={link.ariaLabel}
              >
                {link.text}
              </BaseButton>
            )}
          </div>
        </div>
      </div>
    </SectionStyles>
  );
}
CallOut.propTypes = {
  title: PropTypes.string,
  textAlign: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.shape({
    buttonType: PropTypes.string,
    src: PropTypes.string,
    id: PropTypes.string,
    target: PropTypes.string,
    ariaLabel: PropTypes.string,
    text: PropTypes.string
  }),
  anchorId: PropTypes.string,
  backgroundColor: PropTypes.string
};

CallOut.defaultProps = {
  title: null,
  textAlign: null,
  body: null,
  link: null,
  anchorId: null,
  backgroundColor: null
};

export default CallOut;
