import React from 'react';
import { Divider } from 'aether-marketing';
import styled from 'styled-components';
import BaseButton from './buttons/BaseButton';
import SectionHeader from './SectionHeader';
import LogoWall from './LogoWall';
import { theme } from '../Theme';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};
  h2 {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 16px;
    letter-spacing: 0.48px;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const runtime = typeof document === 'object';
let viewed;

function LogoWallSection(data) {
  const {
    buttonLeft,
    buttonRight,
    backgroundColor,
    body,
    divider,
    link,
    paddingBottom,
    textAlign,
    title
  } = data;

  // Left-aligned is default, but center is supported
  const textAlignToggle =
    textAlign === 'center' ? ' offset-md-2 text-center' : null;

  // Amplitude - "scroll into view"  events
  const { event } = data;
  const target = runtime && document.getElementById('CustomersSection');
  if (runtime && target && event && !viewed) {
    target.setAttribute('data-event-view', event);
    viewed = true;
  }

  return (
    <>
      <SectionStyles
        id="CustomersSection"
        paddingBottom={
          buttonLeft ? paddingBottom : `calc(${paddingBottom || '40px'} - 2rem)`
        }
        backgroundColor={`${backgroundColor || ''}`}
      >
        <div className="container">
          <div className="row">
            <SectionHeader
              title={title}
              body={body}
              link={link}
              textAlign={textAlign}
              bottomMargin="60px"
            />
          </div>
          <LogoWall data={data} />
          {buttonLeft && (
            <div className="row mt-5">
              <div className={`col-sm-12 col-md-8${textAlignToggle}`}>
                {buttonLeft && buttonLeft.text && buttonLeft.src && (
                  <BaseButton
                    buttonType={buttonLeft.buttonType}
                    as="a"
                    src={buttonLeft.src}
                    target={buttonLeft.target}
                    id={buttonLeft.id}
                    gaCategory={buttonLeft.gaCategory}
                    gaLabel={buttonLeft.gaLabel}
                    event={buttonLeft.event}
                    eventProp={buttonLeft.eventProp}
                    className="mb-3 mb-md-0"
                    icon={buttonLeft.icon}
                  >
                    {buttonLeft.text}
                  </BaseButton>
                )}
                {buttonRight && buttonRight.text && buttonRight.src && (
                  <BaseButton
                    buttonType={buttonRight.buttonType}
                    as="a"
                    className="mr-0"
                    src={buttonRight.src}
                    target={buttonRight.target}
                    id={buttonRight.id}
                    gaCategory={buttonRight.gaCategory}
                    gaLabel={buttonRight.gaLabel}
                    event={buttonRight.event}
                    eventProp={buttonRight.eventProp}
                    icon={buttonRight.icon}
                  >
                    {buttonRight.text}
                  </BaseButton>
                )}
              </div>
            </div>
          )}
        </div>
      </SectionStyles>
      {divider ? <Divider /> : null}
    </>
  );
}
export default LogoWallSection;
