import React from 'react';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import Table from './Table';
import BaseButton from './buttons/BaseButton';

const Wrapper = styled.div`
  z-index: 2;
  position: relative;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
    'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';

  @media (min-width: 600px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Content = styled.div`
  margin-bottom: 80px;
  margin-right: 0px !important;

  h1 {
    font-size: 70px;
  }
  /* .img-container {
    padding: 0px 0px 40px 0 !important;
  } */

  @media (max-width: 1292px) {
    margin-bottom: 0px;
    margin-left: 0 !important;
    text-align: center;
    h1 {
      font-size: 50px;
    }
    img {
      margin-left: 0;
    }
  }
  label {
    display: none;
  }
`;

const TableWrapper = styled.div`
  padding-left: 0px !important;
  section {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 14px;
    .container {
      padding-left: 0px !important;
    }
  }
  th:first-of-type {
    width: 22% !important;
  }
  @media (max-width: 900px) {
    th:first-of-type {
      width: 35% !important;
    }
  }
`;
const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0 auto;
`;
/* eslint-disable react/prop-types */
function TableSideXSide({
  title,
  subtitle,
  button,
  divider,
  media,
  table,
  anchorId,
  titleLine,
  textAlign
}) {
  return (
    <Wrapper className="container px-1 px-lg-0 " id={anchorId}>
      <Content className="row mb-0">
        <div className="col-lg-7 order-1 order-lg-0 align-self-center  ">
          {title && <h2 className={`${textAlign}`}>{title}</h2>}
          {titleLine && <OrangeLine className="mb-3" />}
          {subtitle && (
            <div dangerouslySetInnerHTML={{ __html: subtitle.join('') }} />
          )}
          {button && (
            <BaseButton
              buttonType={button.buttonType}
              as="a"
              src={button.src}
              target={button.target}
              id={button.id}
              gaCategory={button.gaCategory}
              gaLabel={button.gaLabel}
              dataTest={button.id}
              className=""
            >
              {button.text}
            </BaseButton>
          )}
          {table && (
            <TableWrapper className="text-left w-100 pt-0">
              <Table
                title={table.title}
                textAlign={table.textAlign}
                headers={table.headers}
                items={table.items}
                paddingBottom={table.paddingBottom}
                paddingTop={table.paddingTop}
                subtitle={table.subtitle}
              />
            </TableWrapper>
          )}
        </div>
        <div className="col-8 col-lg-5 mx-auto col-lg-4 offset-lg-1 align-self-center mb-lg-0 mb-3 ">
          {media && (
            <img
              src={media.src}
              className={media.className}
              alt={media.alt}
              loading="lazy"
            />
          )}
        </div>
      </Content>

      {divider && <Divider />}
    </Wrapper>
  );
}
/* eslint-enable react/prop-types */
export default TableSideXSide;
