import React from 'react';
import { Divider } from 'aether-marketing';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseLink from '../buttons/BaseLink';
import CardPresenterHorizontal from './CardPresenterHorizontal';
import CardBasic from './CardBasic';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0px auto 80px auto;
`;
const SectionStyled = styled.div`
  padding: 80px 0 80px 0;
  h2,
  p {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
  }
  .header {
    padding-bottom: 80px;
  }
`;
function CardBasicGrid({
  header,
  items,
  cta,
  divider,
  textAlign,
  body,
  titleLine,
  headerAlign
}) {
  return (
    <SectionStyled>
      <section>
        <div className="container">
          {/* Optional "header" above grid. */}
          {header && (
            <div className="header">
              <h2 className={`mb-3 ${headerAlign || 'text-center'} `}>
                {header}
              </h2>
              {body && <p>{body}</p>}
              {titleLine && <OrangeLine className="" />}
            </div>
          )}
          <div className="row justify-content-center">
            {items.map((item) => {
              // Presenter Card used on Speakers (Postman Intergalactic, Breaking Changes)
              if (item.type === 'CardPresenterHorizontal') {
                return (
                  <CardPresenterHorizontal
                    key={pmUuid()}
                    col={item.col}
                    imageShape={item.imageShape}
                    media={{
                      alt: `${item.speaker.name}, ${item.speaker.title} ${item.speaker.company}`,
                      src: item.media.src
                    }}
                    speaker={{
                      name: item.speaker.name,
                      title: item.speaker.title,
                      company: item.speaker.company,
                      linkedin: item.speaker.linkedin,
                      twitter: item.speaker.twitter,
                      website: item.speaker.website
                    }}
                  />
                );
              }
              return (
                <CardBasic
                  key={pmUuid()}
                  col={item.colWidth}
                  textPadding={item.textPadding}
                  textAlign={textAlign}
                  eyebrow={item.eyebrow}
                  tags={item.tags}
                  banner={item.banner}
                  media={item.media}
                  header={item.header}
                  body={item.body}
                  link={item.link}
                  hover={item.hover}
                  clickableCardLink={item.clickableCardLink}
                  href={item.link && item.link.src && item.link.src}
                  target={item.link && item.link.target && item.link.target}
                  ratio={item.ratio}
                  type={item.link.type || 'arrowLink'}
                  roundedCorners={item.roundedCorners && item.roundedCorners}
                  borderRadius={item.borderRadius}
                  backgroundColor={item.backgroundColor}
                  className="mb-4"
                />
              );
            })}
          </div>
          {/* An optional BaseLink centered CTA.  For example, homepages "Postman's Customer Stories" section  */}
          {cta && cta.ctaText && cta.src && (
            <div className="row mt-3">
              <div
                className={`col-12${textAlign === '' ? null : ' text-center'}`}
              >
                <BaseLink
                  alternative={cta.alternative ? cta.alternative : false}
                  src={cta.src}
                  target={cta.target}
                >
                  {cta.ctaText}
                </BaseLink>
              </div>
            </div>
          )}
        </div>
      </section>
      {(divider === true && <Divider />) || null}
    </SectionStyled>
  );
}

export default CardBasicGrid;
CardBasicGrid.propTypes = {
  cta: PropTypes.shape({
    ctaText: PropTypes.string,
    src: PropTypes.string,
    alternative: PropTypes.string,
    target: PropTypes.string
  }),
  textAlign: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      company: PropTypes.string,
      linkedin: PropTypes.string,
      twitter: PropTypes.string,
      website: PropTypes.string,
      japan: PropTypes.string
    })
  ),
  titleLine: PropTypes.string,
  header: PropTypes.string,
  headerAlign: PropTypes.string,
  body: PropTypes.string,
  divider: PropTypes.bool
};

CardBasicGrid.defaultProps = {
  cta: null,
  textAlign: null,
  items: null,
  titleLine: null,
  header: null,
  headerAlign: null,
  body: null,
  divider: false
};
