import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../Theme';

const Section = styled.section`
  .alternative p {
    color: white;
  }
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
`;

function LeftTitleWithText({
  id,
  colWidth,
  padding,
  containerClass,
  items,
  backgroundColor,
  containerPadding,
  ContainerBackgroundColor,
  row,
  title
}) {
  const elId = id !== undefined ? id : '';

  const bodyContent = items || [];

  return (
    <Section
      style={{
        backgroundColor,
        padding: padding || '80px 0 80px 0'
      }}
    >
      <div
        className={`${containerClass || 'container'}`}
        style={{
          padding: containerPadding,
          backgroundColor: ContainerBackgroundColor
        }}
      >
        <div
          className={`row ${row && row.class ? row.class : 'mb-3'} `}
          style={{
            backgroundColor:
              row && row.backgroundColor ? row.backgroundColor : '',
            borderRadius: row && row.borderRadius ? row.borderRadius : '',
            padding: row && row.padding && row.padding
          }}
        >
          <div className={`${colWidth || 'col-lg-8 col-sm-12'} text-left `}>
            {title && (
              <h2 className="mb-4" id={elId}>
                {title}
              </h2>
            )}
            <div dangerouslySetInnerHTML={{ __html: bodyContent.join('') }} />
          </div>
        </div>
      </div>
    </Section>
  );
}

LeftTitleWithText.propTypes = {
  id: PropTypes.string,
  colWidth: PropTypes.string,
  padding: PropTypes.string,
  containerClass: PropTypes.string,
  items: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  containerPadding: PropTypes.string,
  ContainerBackgroundColor: PropTypes.string,
  row: PropTypes.shape({
    class: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    padding: PropTypes.string
  }),
  title: PropTypes.string
};
LeftTitleWithText.defaultProps = {
  id: '',
  colWidth: '',
  padding: '',
  containerClass: '',
  items: [],
  backgroundColor: '',
  containerPadding: '',
  ContainerBackgroundColor: '',
  row: {},
  title: ''
};

export default LeftTitleWithText;
