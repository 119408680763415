import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Section = styled.section`
  padding: 80px 0;

  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
    'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
  p {
    font-size: 15px;
  }
  .wrapper {
    background: ${(props) => props.theme.colors.grey_30};
    padding: 10px;
  }
  @media (max-width: 900px) {
    .wrapper img {
      margin: 0 auto;
    }
  }
`;

// const runtime = typeof document === 'object';

function QRHero({ media }) {
  // if (runtime) {
  //   const bodyTarget = document.getElementById('QRHeroBody');
  //   const qrTarget = document.getElementById('QRHeroCode');
  //   const locationSearch = document.location.search;
  //   const qrSplit = locationSearch && locationSearch.split('qrCode=');
  //   const qrCodeFromURL = qrSplit && qrSplit.pop().split('&').shift();
  //   const qrCodeDataFromLocalStorage = localStorage.getItem('qrCode');
  //   const qrCodeData =
  //     (qrCodeDataFromLocalStorage && JSON.parse(qrCodeDataFromLocalStorage)) ||
  //     {};
  //   const qrCodeDataKeys = Object.keys(qrCodeData);
  //   const qrCode =
  //     qrCodeData[`_${qrCodeFromURL}`] ||
  //     (qrCodeDataKeys && qrCodeData[qrCodeDataKeys[0]]) ||
  //     -1;

  //   if (qrTarget && qrCode) {
  //     setTimeout(() => {
  //       const qrURL = `https://api.zuddlevents.com/api/ticketType/create-qr-code?size=120&data=${qrCode}`;

  //       qrTarget.innerHTML = `
  //       <h2>${title}</h2>
  //       <div class="wrapper row my-5 ml-0">
  //         <img src=${qrURL} alt="qr code for registration" height="120" width="120" />
  //         <div class="mt-2 mt-lg-0 ml-1 ml-4 align-self-center">
  //           <p class="mb-0">当日会場にてご登録いただいた場合、この </p>
  //           <p class="mb-0">
  //             QR コードを受付スタッフにお見せください。
  //           </p>
  //         </div>
  //       </div>
  //       `;
  //     }, 1000);
  //   }

  //   if (bodyTarget && body) {
  //     setTimeout(() => {
  //       bodyTarget.innerHTML = body.join('');
  //     }, 1000);
  //   }
  // }

  return (
    <Section>
      <div className="container pl-lg-0">
        <div className="row pb-5">
          <div className="col-lg-7 order-1 order-lg-0">
            <div id="QRHeroCode" />

            <p id="QRHeroBody" className="">
              読み込み中
            </p>
          </div>

          <div className="col-lg-4 offset-lg-1 align-self-center">
            {media && (
              <img
                src={media.src}
                className="img-fluid mb-2 mb-lg-0"
                alt={media.alt}
              />
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default QRHero;
QRHero.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    })
  )
};
QRHero.defaultProps = {
  media: null
};
