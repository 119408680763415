import React from 'react';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import PropTypes from 'prop-types';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
const TableStyles = styled.table`
  & th {
    border: none;
  }
  & td {
    border-bottom: 1px solid #dee2e6;
  }

  & th,
  td {
    padding: 1.6rem 0.5rem;
    position: relative;
    @media (min-width: 650px) {
      padding: 1.6rem;
    }
  }
  th:first-of-type {
    width: 20%;
  }
  th:nth-child(3) {
    width: 40%;
  }
  & thead {
    border-top-color: transparent;
    th {
      border-bottom-width: 1px;
    }
  }
`;
const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0 auto;
`;

function Table({ title, textAlign, titleLine, headers, items, divider }) {
  return (
    <>
      <SectionStyles>
        <div className="container">
          {title && (
            <div className=" mb-2">
              <h2 className={`${textAlign}`}>{title}</h2>
              {titleLine && <OrangeLine className="mb-3" />}
            </div>
          )}
          <div className="row">
            <div className="col-12 pl-0 pr-0">
              <div className="table-responsive table table-sm mb-2">
                <TableStyles className="table">
                  <thead>
                    <tr>
                      {headers.map((h) => (
                        <th key={pmUuid()} scope="col">
                          <p className="font-weight-bold mb-0">{h}</p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item) => (
                      <tr key={pmUuid()}>
                        {Object.keys(item).map((key) => {
                          if (key === 'url') {
                            return (
                              <td key={pmUuid()}>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer nofollow"
                                >
                                  {item.url}
                                </a>
                              </td>
                            );
                          }
                          if (key === 'link') {
                            return (
                              <td key={pmUuid()}>
                                <a
                                  href={item.link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.link.title}
                                </a>
                              </td>
                            );
                          }
                          if (key === 'notAvailable') {
                            return (
                              <td key={pmUuid()}>
                                <p className="mb-0 small">
                                  <img
                                    style={{ opacity: '.6', height: '16px' }}
                                    alt="Unavailable"
                                    src="https://voyager.postman.com/icon/x-icon-grey.svg"
                                  />
                                </p>
                              </td>
                            );
                          }
                          if (key === 'available') {
                            return (
                              <td key={pmUuid()}>
                                <p className="mb-0 small">
                                  <img
                                    style={{ height: '16px' }}
                                    alt="Available"
                                    src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                                  />
                                </p>
                              </td>
                            );
                          }
                          return (
                            <td
                              style={{ whiteSpace: 'pre-wrap' }}
                              key={pmUuid()}
                              dangerouslySetInnerHTML={{ __html: item[key] }}
                            />
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </TableStyles>
              </div>
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  textAlign: PropTypes.string,
  titleLine: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  divider: PropTypes.bool
};
Table.defaultProps = {
  title: null,
  textAlign: 'text-center',
  titleLine: true,
  headers: [],
  items: [],
  divider: false
};
export default Table;
