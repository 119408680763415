import React from 'react';
import styled from 'styled-components';
// import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Divider } from 'aether-marketing';
import BaseLink from '../buttons/BaseLink';
import BaseButton from '../buttons/BaseButton';
import InputBox from '../InputBox';
/* eslint-enable */
import { theme } from '../../Theme';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const ContentWrapper = styled.div`
  p {
    box-sizing: inherit;
    display: block;
    line-height: 1.57143;
  }
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
`;
const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #ffffff;

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const ParentTitleText = styled.p`
  color: ${theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const ParentTitleTemp = styled.p`
  color: ${theme.colors.grey_90};
  font-weight: 600;
`;

const ParentSubtitleText = styled.p`
  color: ${theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

function Hero({
  heroLayout,
  sideXSideLayout,
  divider,
  backgroundColor,
  backgroundImage,
  title,
  subtitle,
  body,
  contentAlignment,
  button,
  media,
  backlink,
  parentTitle,
  parentTitleTemp,
  parentSubtitle,
  inputTitle,
  inputQuery,
  buttonText
}) {
  // Determine column widths
  let colLeft;
  let colRight;
  if (sideXSideLayout === 'small') {
    colLeft = 'col-lg-4 offset-lg-0';
    colRight = 'col-lg-7 offset-lg-1';
  }
  if (sideXSideLayout === 'medium') {
    colLeft = 'col-lg-5 offset-lg-0';
    colRight = 'col-lg-6 offset-lg-1';
  }
  if (sideXSideLayout === 'xlarge') {
    colLeft = 'col-lg-7 offset-lg-0';
    colRight = 'col-lg-4 offset-lg-1';
  }
  const marginTop = backlink || parentTitle ? '' : 'mt-4';
  // Functions displaying content either left, right, top or bottom
  // *******************************************************************************
  const displayLeft = (
    <div className={`col-12 ${colLeft} d-flex align-items-center`}>
      <div className={`${marginTop} text-center text-lg-left`}>
        {backlink && (
          <div className="row">
            <div className="col-12 mb-4">
              <BaseLink
                src={backlink.src}
                className={backlink.className}
                linkType="reversedArrowLink"
              >
                {backlink.text}
              </BaseLink>
            </div>
          </div>
        )}
        {parentTitle && (
          <div className="row">
            <div className="col-12">
              <ParentTitleText>{parentTitle}</ParentTitleText>
            </div>
          </div>
        )}
        {parentTitleTemp && (
          <div className="row">
            <div className="col-12">
              <ParentTitleTemp>{parentTitleTemp}</ParentTitleTemp>
            </div>
          </div>
        )}
        <h1 dangerouslySetInnerHTML={{ __html: title.join('') }} />
        {parentSubtitle && (
          <div className="row">
            <div className="col-12">
              <ParentSubtitleText>{parentSubtitle}</ParentSubtitleText>
            </div>
          </div>
        )}
        {subtitle && (
          <ContentWrapper
            dangerouslySetInnerHTML={{ __html: subtitle.join('') }}
          />
        )}
        {inputQuery && (
          <InputBox
            title={inputTitle}
            inputQuery={inputQuery}
            buttonText={buttonText}
          />
        )}
        <div className="row justify-content-center justify-content-lg-start">
          {button &&
            Array.isArray(button) &&
            button.map((cta) => (
              <div className="mb-4 ml-2" key={pmUuid()}>
                <BaseButton
                  buttonType={cta.buttonType}
                  as="a"
                  src={cta.src}
                  target={cta.target}
                  id={cta.id}
                  gaCategory={cta.gaCategory}
                  gaLabel={cta.gaLabel}
                  className={cta.type === 'inline' ? 'pl-0' : null}
                  icon={cta.icon}
                  dataTest={cta.id}
                >
                  {cta.text}
                </BaseButton>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  const displayRight = (
    <div
      className={`col-12 ${colRight} d-flex align-items-center justify-content-center`}
    >
      <img
        alt={media.alt}
        className={
          media.shadow === true
            ? `media-shadow-v6 img-fluid`
            : `img-fluid mt-5 mt-lg-0`
        }
        height="576"
        src={media.src}
        width="576"
        fetchpriority="high"
        loading="eager"
      />
    </div>
  );

  const displayTop = (
    <div className="col-12 text-center">
      {media && media.src && (
        <img
          alt={media.alt}
          className={`mb-5 img-fluid ${media.class ? ` ${media.class}` : ''}`}
          height="528"
          src={media.src}
          width="1130"
          fetchpriority="high"
          loading="eager"
        />
      )}
    </div>
  );

  const displayBottom = (
    <>
      <div className="col-12 text-center">
        <h1 dangerouslySetInnerHTML={{ __html: title.join('') }} />
      </div>
      <ContentWrapper
        className={`col-12 col-md-8 offset-md-2 ${
          contentAlignment ? `text-${contentAlignment}` : ''
        }`}
      >
        {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
      </ContentWrapper>
      {button && Array.isArray(button) && (
        <div className=" col-12 text-center mb-sm-5">
          {button.map((cta) => (
            <div className="mb-4 d-sm-inline" key={pmUuid()}>
              <BaseButton
                buttonType={cta.buttonType}
                as="a"
                src={cta.src}
                target={cta.target}
                id={cta.id}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                className={cta.type === 'inline' ? 'pl-0' : null}
                icon={cta.icon}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseButton>
            </div>
          ))}
        </div>
      )}
    </>
  );

  // conditionally displays above defined functions(displayBottom, displayTop, displayRight, displayLeft)
  // *******************************************************************************
  return (
    <>
      <SectionStyles
        backgroundColor={backgroundColor}
        style={{
          backgroundImage,
          backgroundSize: 'cover'
        }}
      >
        <div className="container">
          <div className="row">
            {/* Displays 2 pieces of content side by side */}
            {heroLayout === 'sideXSide' && (
              <>
                {displayLeft}
                {displayRight}
              </>
            )}
            {/* Displays 2 pieces content on top of each other */}
            {heroLayout === 'topXBottom' && (
              <>
                {displayTop}
                {displayBottom}
              </>
            )}
            {/* Displays 1 piece of content centered */}
            {heroLayout === 'centered' && (
              <div
                className={`col-12 ${
                  contentAlignment ? `text-${contentAlignment}` : 'text-center'
                }`}
              >
                <h1
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: title.join('') }}
                />
                {subtitle && (
                  <ContentWrapper
                    dangerouslySetInnerHTML={{ __html: subtitle.join('') }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </SectionStyles>
      {(divider !== false && <Divider fullWidth />) || null}
    </>
  );
}

Hero.propTypes = {
  heroLayout: PropTypes.string.isRequired,
  sideXSideLayout: PropTypes.string,
  divider: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  inputTitle: PropTypes.string,
  inputQuery: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.arrayOf(PropTypes.string),
  contentAlignment: PropTypes.string,
  parentTitle: PropTypes.string,
  parentTitleTemp: PropTypes.string,
  parentSubtitle: PropTypes.string,
  backlink: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string
  })
};

Hero.defaultProps = {
  sideXSideLayout: null,
  divider: false,
  backgroundColor: null,
  backgroundImage: null,
  inputTitle: null,
  inputQuery: null,
  buttonText: null,
  subtitle: null,
  body: null,
  contentAlignment: null,
  parentTitle: null,
  parentTitleTemp: null,
  parentSubtitle: null,
  backlink: null,
  button: null,
  media: null
};

export default Hero;
