import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseLink from './buttons/BaseLink';
import SectionHeader from './SectionHeader';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

export const BlockQuoteStyles = styled.blockquote`
  border: 0;
  display: block;
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 80px;
  margin-top: 80px;
  position: relative;
  z-index: 10;

  &::after,
  &::before {
    position: absolute;
    color: ${(props) => props.theme.colors.orange_40};
    z-index: 1;
  }

  &::before {
    content: '“';
    top: -15px;
    left: -25px;
    font-size: 48px;
    font-family: Georgia, Times, 'Times New Roman', serif;
  }

  &::after {
    content: '”';
    right: -2.4rem;
    bottom: 2rem;
    font-size: 4.8rem;
    font-family: Georgia, Times, 'Times New Roman', serif;
    @media screen and (max-width: 576px) {
      right: 0px;
    }
  }

  cite p {
    font-size: 1.6rem; /* 16px */
    font-style: normal;
    margin-top: 24px;
    & strong {
      font-family: ${(props) => props.theme.fonts.Inter};
      font-weight: 600;
    }
  }
`;

function QuoteLarge({
  title,
  body,
  link,
  textAlign,
  quote,
  citationName,
  citationTitle,
  ctaLinks
}) {
  return (
    <div style={{ paddingTop: '0', paddingBottom: '40px' }}>
      <div className="container">
        {title ? (
          <div className="row">
            <div
              className="col-12 offset-lg-3 mb-5 pl-5"
              style={{ marginTop: '80px' }}
            >
              <SectionHeader
                title={title}
                body={body}
                link={link}
                textAlign={textAlign}
                bottomMargin="0"
              />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <BlockQuoteStyles>
              <span>{quote}</span>
              <cite>
                <p>
                  <strong>{citationName}</strong>
                  {`, ${citationTitle}`}
                </p>
              </cite>
            </BlockQuoteStyles>
            {ctaLinks && (
              <span>
                {ctaLinks &&
                  Array.isArray(ctaLinks) &&
                  ctaLinks.map((cta) => (
                    <p className="ml-5" key={pmUuid()}>
                      <BaseLink
                        linkType={cta.type}
                        src={cta.src}
                        className={cta.className && cta.className}
                        alternative={cta.alternative ? cta.alternative : false}
                        target={cta.target ? cta.target : 'same-tab'}
                        gaCategory={cta.gaCategory && cta.gaCategory}
                        gaLabel={cta.gaLabel && cta.gaLabel}
                        id={cta.id && cta.id}
                        ariaLabel={cta.ariaLabel && cta.ariaLabel}
                      >
                        {cta.text}
                      </BaseLink>
                    </p>
                  ))}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

QuoteLarge.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
  textAlign: PropTypes.string,
  quote: PropTypes.string,
  citationName: PropTypes.string,
  citationTitle: PropTypes.string,
  ctaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  )
};

QuoteLarge.defaultProps = {
  title: '',
  body: '',
  link: '',
  textAlign: 'left',
  quote: '',
  citationName: '',
  citationTitle: '',
  ctaLinks: []
};

export default QuoteLarge;
