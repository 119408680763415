import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import { BlockQuoteStyles } from './QuoteLarge';
import AspectRatioImagePlugin from './cards/AspectRatioImagePlugin';
import { theme } from '../Theme';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};
  h2 {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 16px;
    letter-spacing: 0.48px;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
const SliderContainer = styled(SectionStyles)`
  height: ${(props) => !props.toggleIcons && '350px'};

  @media screen and (max-width: 768px) {
    margin-bottom: ${(props) => (props.toggleIcons ? '40px' : '0')};
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 80px;
  }
`;
const ContentContainer = styled.div`
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }

  blockquote {
    ${BlockQuoteStyles.componentStyle.rules}
    font-size: 22px;
    margin-top: ${(props) => (props.toggleIcons ? '40px' : '0')};
    margin-bottom: 0;
    @media screen and (max-width: 576px) {
      font-size: 18px;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

const CarouselIndicators = styled.ol`
  width: 100%;
  margin: 0;
  border: none;
  li {
    text-indent: 0px;
    background: transparent;
    margin: 0 24px;
    width: 120px;
    border: none;
    filter: grayscale(100%);
    &.active {
      filter: grayscale(0%);
    }
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    bottom: -40px;
  }
`;

const CarouselBody = styled.div`
  height: 100%;
  @media screen and (min-width: 768px) {
    height: ${(props) =>
      props.toggleIcons === true && props.items.length > 1 ? '310px' : '100%'};
  }
`;

const CarouselIcon = styled.span`
  &.carousel-control-prev-icon,
  &.carousel-control-next-icon {
    background-image: none;
  }
`;

const ContentIcons = styled.img`
  height: 40px;
  position: relative;
`;
function Slider(props) {
  const { items, backgroundColor, padding, toggleIcons, divider, interval } =
    props;
  return (
    <>
      <SliderContainer
        toggleIcons={toggleIcons}
        style={{ backgroundColor: `${backgroundColor}`, padding: `${padding}` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                id="company"
                className="carousel slide"
                data-pause={false}
                data-ride="carousel"
                data-interval={interval}
              >
                <CarouselBody
                  className="carousel-inner"
                  toggleIcons={toggleIcons}
                  items={items}
                >
                  {items.map((item) => (
                    <div
                      key={pmUuid()}
                      className={
                        item.index.toString() === '0'
                          ? 'carousel-item active'
                          : 'carousel-item'
                      }
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-lg-8 offset-lg-2">
                            {toggleIcons && (
                              <div className="d-flex justify-content-center">
                                <ContentIcons
                                  src={item.media.src}
                                  alt={item.media.alt}
                                />
                              </div>
                            )}
                            <ContentContainer toggleIcons={toggleIcons}>
                              {item.body && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.body.join('')
                                  }}
                                />
                              )}
                            </ContentContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </CarouselBody>
                <CarouselIndicators className="carousel-indicators">
                  {items.length > 1 &&
                    items.map((item) => {
                      return (
                        <li
                          key={pmUuid()}
                          data-target="#company"
                          data-slide-to={item.index}
                          className={
                            item.index.toString() === '0' ? 'active' : ''
                          }
                        >
                          {toggleIcons && items.length > 1 && (
                            <AspectRatioImagePlugin
                              src={item.media.src}
                              role="img"
                              ratio={
                                item.media.ratio ? item.media.ratio : '1/1'
                              }
                              aria-label={item.media.alt || ''}
                              height={
                                item.media.height ? item.media.height : '100%'
                              }
                              marginBottom={
                                item.media.marginBottom
                                  ? item.media.marginBottom
                                  : 0
                              }
                            />
                          )}
                        </li>
                      );
                    })}
                </CarouselIndicators>
                {items.length > 1 ? (
                  <>
                    <a
                      className="carousel-control-prev"
                      href="#company"
                      role="button"
                      data-slide="prev"
                    >
                      <CarouselIcon
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#company"
                      role="button"
                      data-slide="next"
                    >
                      <CarouselIcon
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Next</span>
                    </a>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </SliderContainer>
      {(divider === true && <Divider />) || null}
    </>
  );
}

Slider.propTypes = {
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  toggleIcons: PropTypes.bool,
  divider: PropTypes.bool,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.string,
        media: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          ratio: PropTypes.string
        }),
        body: PropTypes.arrayOf([null])
      })
    )
  ]),
  interval: PropTypes.string
};

Slider.defaultProps = {
  items: null,
  backgroundColor: '#FFFFFF',
  padding: null,
  toggleIcons: true,
  divider: false,
  interval: '5000'
};

export default Slider;
