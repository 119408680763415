import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../Theme';
import { Primary } from './buttons/BaseButtonStyles';

const SearchForm = styled.form`
  input[name='FormButton'] {
    ${Primary.componentStyle.rules}
    border: 1px solid transparent;
    border-radius: 0 4px 4px 0;
    font-weight: 600;
    padding: 8px 16px;
    transition: ${theme.transitions.all};
  }
`;

const InputWrapper = styled.div`
  display: flex;
`;

const InputField = styled.input`
  background: ${theme.colors.grey_00};
  border: 1px solid ${theme.colors.orange_50};
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  box-shadow: none;
  color: ${theme.colors.grey_40};
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  margin: 0;
  outline: 0;
  padding: 0 12px;
  transition: ${theme.transitions.all};
  width: 100%;
  -webkit-appearance: none;

  :focus {
    border: 1px solid ${theme.colors.orange_50};
    color: #555;
  }
  /* clears the ‘X’ from Internet Explorer */
  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

function InputBox({ buttonText, title, inputQuery }) {
  const [query, setQuery] = useState('');
  /* open query in another tab */
  function toggleSearchResult() {
    const sLink = document.createElement('a');
    sLink.target = '_blank';
    sLink.href = `${query}`;
    sLink.click();
  }
  const handleChange = (e) => {
    setQuery(`${inputQuery}${e}`);
  };
  const handleButtonClick = () => {
    const res = document.getElementById('input').value;
    setQuery(`${inputQuery}${res}`);
    toggleSearchResult();
  };
  /* search on enter key */
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      toggleSearchResult();
    }
  };
  return (
    <>
      <h2>{title}</h2>
      <SearchForm action="#" method="POST" target="_blank">
        <InputWrapper>
          <InputField
            onChange={(e) => handleChange(e.target.value)}
            onKeyPress={handleKeyPress}
            type="search"
            placeholder="jsmith@example.com"
            autoComplete="off"
            aria-label="jsmith@example.com"
            id="input"
          />
          {/* Do not use Aether-Marketing Buttons for Zendesk Search Form */}
          <input
            type="button"
            name="FormButton"
            value={buttonText}
            onClick={handleButtonClick}
          />
        </InputWrapper>
      </SearchForm>
    </>
  );
}

InputBox.propTypes = {
  inputQuery: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string
};

InputBox.defaultProps = {
  inputQuery: '',
  title: '',
  buttonText: ''
};

export default InputBox;
