import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/* eslint-enable */
import BaseButton from './buttons/BaseButton';
import { getAnchorId } from '../../utils/functions';
import { theme } from '../Theme';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const SectionHeaderStyles = styled.div`
  padding-bottom: ${(props) => props.paddingBottom || '80px '};
`;

const Body = styled.div`
  a {
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
`;

function SectionHeader({
  title,
  assignIDtoParent,
  body,
  link,
  paddingBottom,
  textAlign,
  className
}) {
  const anchorTag = title ? getAnchorId(title) : null;
  const textAlignToggle1 = textAlign === 'center' ? ' offset-md-2' : '';
  const textAlignToggle2 = textAlign ? ` text-${textAlign}` : '';

  return (
    <SectionHeaderStyles
      className={`col-sm-12 col-md-8 ${textAlignToggle1} ${textAlignToggle2} ${className}`}
      paddingBottom={paddingBottom}
    >
      {title && <h2 id={assignIDtoParent ? '' : anchorTag}>{title}</h2>}
      {body && body.length > 0 && (
        <Body
          dangerouslySetInnerHTML={{ __html: body.join('') }}
          key={pmUuid()}
        />
      )}
      {link && (
        <BaseButton
          buttonType={link.buttonType}
          as={'' || 'a'}
          className={link.buttonType === 'inline' ? 'pl-0' : null}
          gaCategory={link.gaCategory}
          gaLabel={link.gaLabel}
          src={link.src}
          target={link.target}
          icon={link.icon}
          id={link.id}
        >
          {link.text}
        </BaseButton>
      )}
    </SectionHeaderStyles>
  );
}

SectionHeader.defaultProps = {
  title: '',
  body: null,
  assignIDtoParent: null, // dynamic - if true, assign ID to parent section container insead of child
  paddingBottom: '80px',
  link: null,
  className: '',
  textAlign: ''
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  assignIDtoParent: PropTypes.bool,
  paddingBottom: PropTypes.string,
  textAlign: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        button: PropTypes.shape({
          buttonType: PropTypes.string,
          className: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          icon: PropTypes.string,
          id: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ])
};

export default SectionHeader;
