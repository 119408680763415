import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const EmbedFormStyles = styled.div`
  width: 80%;
  height: 100%;
  margin: 40px auto;

  @media (min-width: 650px) {
    width: 50%;
  }
  p {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif' !important;
  }
  button {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    padding: 4px 8px;
    margin: 20px 16px 0 0;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #f26b3a;
    width: 190px;
  }
  button:focus {
    outline: none;
  }
  button:active {
    background-color: #e05320 !important;
  }
  button p {
    margin: 0 auto;
    align-self: center;
  }
  .embeddable-view_button-center__XQ5wp {
    margin-top: 10px;
  }
  .embeddable-view_inputContainer__93LS\+ {
    margin-bottom: 16px !important;
  }
  p {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    padding: 8px 16px;
  }
  .Input_container__yLbOT {
    margin-bottom: 16px;
  }
  .Input_labelContainer__V7\+vK {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }
  .Input_container__yLbOT.Input_labelContainer__V7\+vK > label > p {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif' !important;
  }
  .Input_truncateLargeMaxLenLabel__Hh2VB {
    color: rgb(0, 0, 0, 0.5);
    font-size: 12px;
  }
  .embeddable-view_checkboxRichTextAlignment__J1TKi {
    display: flex;
    margin-top: 16px;
  }
  .embeddable-view_inputContainer__93LS {
    margin-bottom: 10px !important;
  }
  .embeddable-view_richTextAlignment__9N4IP {
    margin-left: 10px;
  }
  .embeddable-view_inputContainer__93LS\+ {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }

  div[id^='react-select-'][id$='-listbox'] {
    background-color: white;
  }
  .success-page_successContainer__nOszT {
    display: none;
  }

  .styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO,
  .styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+:hover {
    color: rgb(17, 171, 71);
    margin-top: 10px;
  }

  .success-page_successContainer__nOszT h2,
  .success-page_successContainer__nOszT p {
    display: none;
  }
  .success-page_successContainer__nOszT svg {
    display: none;
  }
  .Input_required__XJyyS:after {
    color: rgb(229, 29, 63);
    content: '*';
    margin-left: 2px;
  }
  .embeddable-view_requiredLabel__S7h7U:after {
    color: rgb(229, 29, 63);
    content: '*';
    margin-left: 2px;
  }
  .embeddable-view_errorMessageContainer__BRgZN {
    display: flex;
    flex-wrap: wrap;
  }
  .embeddable-view_errorMessageContainer__BRgZN p:first-of-type {
    display: flex;
    align-items: center;
    color: red;
  }
  .embeddable-view_errorMessageContainer__BRgZN button {
    background-color: white;
    border: 2px solid #a6a6a6;
    margin-top: 10px;
  }
  @media (min-width: 768px) {
    .embeddable-view_errorMessageContainer__BRgZN button {
      margin-left: 20px;
      margin-top: 0;
    }
    .styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO.styles_color-default__success__cMwnO,
    .styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+.styles_color-hover__success__UWVK\+:hover {
      margin-left: 20px;
      margin-top: 0px;
    }
  }
  .embeddable-view_errorMessageContainer__BRgZN button p {
    color: black !important;
  }
  div[class^='embeddable-view_disclaimerContainer'] > p > p {
    margin-left: 0px !important;
  }

  .Button_button__wJnO- {
    margin: 0;
  }
  .embeddable-view_errorMessage__3BxyI p {
    color: red !important;
    margin-bottom: 10px;
  }
  p.Body_body__Itk-x.embeddable-view_errorMessage__3BxyI.styles_regular__YxAnY.styles_inter__K1yIQ.Body_b6__zdBTT {
    color: red;
  }
  .styles_color-default__danger__3QcE {
    color: red !important;
  }
  div[class^='embeddable-view_disclaimerContainer'] > p > p {
    font-size: 13px;
    /* padding-top: 20px; */
    margin-left: -7px;
  }
  .embeddable-view_errorMessageContainer__BRgZN {
    font-size: 13px;
    margin-top: 10px;
  }
  .Checkbox_input__lUdgS input:checked {
    margin-left: 25px;
    border: 1px solid blue;
  }
  div[class^='embeddable-view_inputContainer'] {
    padding-bottom: 20px;
  }
`;
const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0 auto;
`;

function EmbedForm({ id }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zuddlevents.com/embed/reg-form.js';
    document.head.appendChild(script);
    const scriptStyles = document.createElement('styles');
    scriptStyles.src = 'https://static.zuddlevents.com/embed/reg-form.css';
    document.head.appendChild(scriptStyles);

    return () => {
      script.remove();
      scriptStyles.remove();
    };
  }, []);

  useEffect(() => {
    const handleRegistrationSuccess = (e) => {
      const { qrCode } = e.detail.values;
      const qrCodeDataFromLocalStorage = localStorage.getItem('qrCode');
      const qrCodeData =
        (qrCodeDataFromLocalStorage &&
          JSON.parse(qrCodeDataFromLocalStorage)) ||
        {};

      qrCodeData[`_${qrCode}`] = qrCode;

      // Save qrCode dat to local storage
      localStorage.setItem('qrCode', JSON.stringify(qrCodeData));
      // Now you can use qrCode value as per your requirement
      window.location.href = `/jp/events/postman-japan-launch-thank-you?qrCode=${encodeURIComponent(
        qrCode
      )}`;
    };

    window.addEventListener(
      'zuddl.registration.success',
      handleRegistrationSuccess
    );

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener(
        'zuddl.registration.success',
        handleRegistrationSuccess
      );
    };
  }, []);
  return (
    <>
      <div className="mt-5 mb-lg-4">
        <h2 className="text-center" style={{ fontFamily: 'Arial' }}>
          参加登録
        </h2>
        <OrangeLine className="mb-3" />
      </div>

      <EmbedFormStyles id={id}>
        <blockquote className="zuddl-form">
          <div
            id="zuddl-embeddable-form"
            data-domain-fallback="true"
            data-zuddl-event-id="1bcb262f-ebf5-45ba-a700-b4b1bc77b7e5"
          />
        </blockquote>
      </EmbedFormStyles>
    </>
  );
}

export default EmbedForm;
EmbedForm.propTypes = {
  id: PropTypes.string.isRequired
};
