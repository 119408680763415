import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider } from 'aether-marketing';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  h2 {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
    line-height: 1.3;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
const Image = styled.img`
  border: ${(props) => props.gif && props.theme.border.gif_border};
  border-radius: ${(props) => props.gif && props.theme.borderRadius.smallv2};
`;

const OrangeLine = styled.div`
  margin: 0;
  width: 50px;
  height: 4px;
  background-color: #f26b3a;
  margin: 0 auto;
`;

function CenteredImage({
  size,
  paddingTop,
  paddingBottom,
  backgroundColor,
  title,
  mobile,
  media,
  divider,
  display = '',
  anchorId,
  titleLine,
  textAlign
}) {
  // Determine size of image
  const layoutString = size.toLowerCase();
  let colWidth;
  switch (layoutString) {
    case 'small':
      colWidth = 'col-lg-6 offset-lg-3';
      break;
    case 'medium':
      colWidth = 'col-md-8 offset-md-2';
      break;
    case 'large':
      colWidth = 'col-lg-10 offset-lg-1';
      break;
    case 'full-width':
      colWidth = 'col-12';
      break;
    default:
      // everything else defaults to medium
      colWidth = 'col-md-8 offset-md-2';
  }

  return (
    <>
      <SectionStyles
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        backgroundColor={backgroundColor}
        id={anchorId}
      >
        {title && (
          <div className=" mb-2">
            <h2 className={`${textAlign}`}>{title}</h2>
            {titleLine && <OrangeLine className="mb-3" />}
          </div>
        )}
        <div className="container">
          <div className="row no-gutters">
            <div className={`col-12 ${colWidth} ${display}`}>
              <Image
                className={
                  mobile
                    ? 'd-none d-md-block img-fluid w-100'
                    : 'img-fluid w-100'
                }
                gif={media.gif}
                src={media.url}
                alt={media.alt}
              />
              {mobile && (
                <Image
                  className="img-fluid d-block d-md-none"
                  gif={media.gif}
                  src={mobile.url}
                  alt={mobile.alt}
                />
              )}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

CenteredImage.propTypes = {
  size: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  mobile: PropTypes.string,
  media: PropTypes.string,
  divider: PropTypes.bool,
  display: PropTypes.string,
  anchorId: PropTypes.string,
  titleLine: PropTypes.bool
};

CenteredImage.defaultProps = {
  size: '',
  paddingTop: null,
  paddingBottom: null,
  backgroundColor: null,
  title: null,
  textAlign: 'text-center',
  mobile: null,
  media: null,
  divider: null,
  display: 'purple',
  anchorId: null,
  titleLine: true
};

export default CenteredImage;
