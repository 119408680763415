import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
/* eslint-enable */

const LogoWrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.colX === '3' ? '' : 'center center'};
  height: ${(props) => (props.colX === '1' ? '46px' : '75px')};
  width: ${(props) => (props.colX === '1' ? '76px' : null)};
  margin: ${(props) => (props.colX === '1' ? '0 auto' : '0 2rem')};
  @media screen and (max-width: 768px) {
    display: flex;
  }

  &:hover {
    &.hover {
      transform: translateY(-0.5rem);
      transition: 0.3s linear;
    }
  }
`;

function LogoWall({ data }) {
  const { colX, media, linkedImages, toggleHover } = data;
  return (
    // "linkedImages" boolean toggles between clickable and static logos
    // "toggleHover" boolean adds a logo animation on mouseover
    <div className="row d-flex mb-5">
      {linkedImages
        ? media.map((img) => (
            <React.Fragment key={pmUuid()}>
              <div
                className={`${
                  colX === '1'
                    ? 'col-4 col-sm-4 col-md-2'
                    : 'col-6 col-sm-6 col-md-4'
                }
                col-lg${colX ? `-${colX}` : ''} mb-5`}
              >
                <a
                  href={img.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-labelledby="listen"
                >
                  <LogoWrapper
                    style={{
                      backgroundImage: `url(${img.src})`
                    }}
                    className={toggleHover ? 'hover' : ''}
                    colX={colX}
                  />
                </a>
              </div>
            </React.Fragment>
          ))
        : media.map((img) => (
            <React.Fragment key={pmUuid()}>
              <div
                className={`${
                  colX === '1'
                    ? 'col-4 col-sm-4 col-md-2'
                    : 'col-6 col-sm-6 col-md-4'
                }
              col-lg${colX ? `-${colX}` : ''} mb-5`}
              >
                <LogoWrapper
                  style={{
                    backgroundImage: `url(${img.src})`
                  }}
                  title={img.alt}
                  colX={colX}
                  className={toggleHover ? 'hover' : ''}
                />
              </div>
            </React.Fragment>
          ))}
    </div>
  );
}

LogoWall.propTypes = {
  data: PropTypes.shape({
    colX: PropTypes.string,
    toggleHover: PropTypes.bool,
    linkedImages: PropTypes.bool,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        href: PropTypes.string,
        alt: PropTypes.string
      })
    )
  })
};

LogoWall.defaultProps = {
  data: {
    colX: '2',
    linkedImages: true,
    media: [],
    toggleHover: null
  }
};
export default LogoWall;
