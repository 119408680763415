import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider } from 'aether-marketing';
import BaseButton from '../buttons/BaseButton';

const HeroSection = styled.div`
  padding: 80px 0;
  background-position: bottom 0px right -65px;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 1350px) {
    background-size: 80%;
  }
  @media (max-width: 992px) {
    background-image: none !important;
  }
`;

function HeroWithLargeImage({
  h1,
  titleNote,
  subtitle,
  button,
  media,
  divider
}) {
  return (
    <>
      <HeroSection
        className="position-relative"
        style={{
          backgroundImage: `url('${media.src}')`
        }}
      >
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-lg-5 order-1 order-lg-0">
              <div className="text-center text-lg-left">
                {h1 && <h1>{h1}</h1>}
                {titleNote && <p className="mb-4">{titleNote}</p>}
                {subtitle && (
                  <div
                    dangerouslySetInnerHTML={{ __html: subtitle.join('') }}
                  />
                )}
                {button && (
                  <BaseButton
                    buttonType={button.buttonType}
                    as="a"
                    src={button.src}
                    target={button.target}
                    id={button.id}
                    gaCategory={button.gaCategory}
                    gaLabel={button.gaLabel}
                    className="mt-4"
                  >
                    {button.text}
                  </BaseButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      {(divider !== false && <Divider fullWidth />) || null}
    </>
  );
}

HeroWithLargeImage.propTypes = {
  h1: PropTypes.string,
  titleNote: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  button: PropTypes.shape({
    buttonType: PropTypes.string,
    text: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string,
    icon: PropTypes.string
  }),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    class: PropTypes.string,
    mediaWidth: PropTypes.string
  }),
  divider: PropTypes.bool
};

HeroWithLargeImage.defaultProps = {
  h1: null,
  titleNote: null,
  subtitle: null,
  button: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  media: null,
  divider: false
};

export default HeroWithLargeImage;
