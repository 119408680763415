import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import { Divider } from 'aether-marketing';
import SectionHeader from './SectionHeader';
import BaseLink from './links/BaseLink';
import FeatureCard from './FeatureCard';
import { theme } from '../Theme';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const BodyCopy = styled.div`
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
`;

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};
  h2 {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 16px;
    letter-spacing: 0.48px;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

function Feature({
  paddingTop,
  paddingBottom,
  paddingBottomHeader,
  divider,
  backgroundColor,
  title,
  className,
  body,
  headerLink,
  textAlign,
  media,
  items,
  id, // for hooks
  footerCopy,
  bodyLink
}) {
  const isSectionHeader = title || (body && body.length > 0) || headerLink;
  return (
    <div>
      <SectionStyles
        paddingTop={paddingTop}
        paddingBottom={paddingBottom || '40px'}
        backgroundColor={backgroundColor}
      >
        <div className="container" id={id || ''}>
          <div className="row">
            {isSectionHeader && (
              <SectionHeader
                title={title}
                body={body}
                className={className}
                paddingBottom={paddingBottomHeader}
                link={headerLink}
                textAlign={textAlign}
              />
            )}
            <div className="col-sm-6 offset-sm-3 col-lg-3 offset-lg-1 text-center">
              {media && (
                <img
                  className="img-fluid mb-5"
                  src={media.src}
                  alt={media.alt}
                />
              )}
            </div>
          </div>
          <div className="row">
            {items.map((item) => (
              <FeatureCard
                key={pmUuid()}
                imageSize={item.imageSize}
                title={item.title}
                subtitle={item.subtitle}
                colWidth={item.colWidth}
                media={item.media}
                link={item.link}
              />
            ))}
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              {footerCopy && (
                <BodyCopy
                  dangerouslySetInnerHTML={{
                    __html: footerCopy.join('')
                  }}
                />
              )}
            </div>
          </div>
          {bodyLink &&
            Array.isArray(bodyLink) &&
            bodyLink.map((cta) => (
              <p key={pmUuid()}>
                <BaseLink
                  linkType={cta.type}
                  src={cta.src}
                  className={cta.className && cta.className}
                  alternative={cta.alternative ? cta.alternative : false}
                  target={cta.target ? cta.target : 'same-tab'}
                  gaCategory={cta.gaCategory && cta.gaCategory}
                  gaLabel={cta.gaLabel && cta.gaLabel}
                  id={cta.id && cta.id}
                  ariaLabel={cta.ariaLabel && cta.ariaLabel}
                >
                  {cta.text}
                </BaseLink>
              </p>
            ))}
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </div>
  );
}

Feature.propTypes = {
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingBottomHeader: PropTypes.string,
  divider: PropTypes.bool,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  headerLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ]),
  textAlign: PropTypes.string,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  footerCopy: PropTypes.arrayOf(PropTypes.string),
  bodyLink: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string
};

Feature.defaultProps = {
  paddingTop: null,
  paddingBottom: null,
  paddingBottomHeader: null,
  divider: null,
  backgroundColor: null,
  title: null,
  body: null,
  headerLink: null,
  textAlign: null,
  media: null,
  items: null,
  id: null,
  footerCopy: null,
  bodyLink: null,
  className: null
};

export default Feature;
