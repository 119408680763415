import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider } from 'aether-marketing';
import { BlockQuoteStyles } from './QuoteLarge';
import { theme } from '../Theme';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) => props.backgroundColor};

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const ContentContainer = styled.div`
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }

  blockquote {
    ${BlockQuoteStyles.componentStyle.rules}
  }
`;

function CaseStudySection({
  title,
  body,
  subtitle,
  topical,
  divider,
  colSize
}) {
  // Determine section topic
  let topicImage;
  if (topical === 'challenge') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-challenge.svg';
  } else if (topical === 'solution') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-solution.svg';
  } else if (topical === 'outcome') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-outcome.svg';
  } else {
    // collapse image section
    // eslint-disable-next-line no-unused-expressions
    null;
  }

  const anchorTag = title.replace(/\s+/g, '-').toLowerCase();

  const mediaSection = (
    <div className="col-12 mb-5">
      <img
        alt=""
        height="343"
        src={topicImage}
        width="1176"
        className="img-fluid"
        fetchpriority="low"
        loading="lazy"
      />
    </div>
  );

  const textSection = (
    <div
      className={`${colSize}` ? `${colSize}` : `col-12 col-md-8 offset-md-2`}
    >
      <div className="text-left">
        <ContentContainer>
          <div className="col-12 col-lg-8 offset-lg-2">
            {title && <h2 id={anchorTag}>{title}</h2>}
            {subtitle && <p className="subtitle mb-4">{subtitle}</p>}
            {body && (
              <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
            )}
          </div>
        </ContentContainer>
      </div>
    </div>
  );

  return (
    <>
      {topicImage && (
        <SectionStyles paddingBottom="0">
          <div className="container">{mediaSection}</div>
        </SectionStyles>
      )}
      <SectionStyles>
        <div className="container">{textSection}</div>
      </SectionStyles>
      {(divider !== false && <Divider />) || null}
    </>
  );
}

function CaseStudy(data) {
  const { title, body, subtitle, topical, divider } = data;

  return (
    <CaseStudy
      title={title}
      subtitle={subtitle}
      body={body}
      topical={topical}
      divider={divider}
    />
  );
}

CaseStudySection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.bool,
  topical: PropTypes.string,
  colSize: PropTypes.string
};

CaseStudySection.defaultProps = {
  title: null,
  body: null,
  subtitle: null,
  divider: null,
  topical: null,
  colSize: null
};

export default CaseStudySection;
