import React from 'react';
import styled from 'styled-components';
/* eslint-enable */
import PropTypes from 'prop-types';
import { Divider } from 'aether-marketing';
import { theme } from '../Theme';
import SectionHeader from './SectionHeader';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const runtime = typeof document === 'object';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) =>
    theme.colors[props.backgroundColor] || theme.colors.white};
  h2 {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 16px;
    letter-spacing: 0.48px;
  }
  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  & .floating-header,
  .floating-body {
    z-index: 0; /* to keep under the blurred navbar */
  }

  @media (min-width: 992px) {
    padding: 5px 0 40px 0;
    & .floating-header,
    .floating-body {
      padding-top: 75px;
    }

    & .floating-header {
      float: left;
      width: 33%;
    }

    & .floating-body {
      float: right;
      width: 67%;
    }
  }
`;

const ListWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.grey_20};
`;

const ListItem = styled.div`
  & a {
    &::after {
      color: ${(props) => props.theme.colors.grey_50};
      content: '→';
      display: block;
      font-size: 24px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateX(-8px) translateY(-50%);
      transition: ${(props) => props.theme.transitions.all};
    }
    &:hover {
      text-decoration: none !important;

      & h3 {
        color: ${(props) => props.theme.colors.blue_70};
        transition: ${(props) => props.theme.transitions.all};
      }
      &::after {
        right: 0;
        transition: ${(props) => props.theme.transitions.all};
      }
    }
  }
`;

const ListStyle = styled.div`
  height: 100%;
  min-width: 100%;
  color: ${(props) => props.theme.colors.grey_90};
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px 96px 24px 0;
`;

const dataShape = {
  body: PropTypes.array,
  date: PropTypes.string,
  items: PropTypes.array,
  url: PropTypes.string,
  logo: PropTypes.string,
  leftlinks: PropTypes.array,
  link: PropTypes.object,
  title: PropTypes.string,
  divider: PropTypes.bool,
  floatingheader: PropTypes.bool
};

function List({ data }) {
  return (
    <ListWrapper className="col-12">
      <ListItem>
        {data.linkToArticle && (
          <a
            href={data.linkToArticle}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListStyle>
              <h3 className="h4">{data.articleTitle}</h3>
              <p className="small mb-0">{data.datePublished}</p>
            </ListStyle>
          </a>
        )}
      </ListItem>
    </ListWrapper>
  );
}

List.propTypes = {
  data: PropTypes.shape(dataShape)
};
List.defaultProps = {
  data: {}
};

class RichList extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    /* eslint-disable global-require, import/no-dynamic-require */
    const content = require(`../../../bff-data/${data.content}.json`);
    /* eslint-enable */
    this.state = {
      content,
      data
    };
    const updateRuntimeContent = (contentString) => {
      this.setState({ content: JSON.parse(contentString) });
    };

    if (runtime) {
      window.pmt('bff', ['press', (d) => updateRuntimeContent(d), true]);
    }
  }

  render() {
    const { content, data } = this.state;
    const padding = data.floatingheader ? '5px' : '80px';
    const backgroundColor = data.backgroundColor
      ? data.backgroundColor
      : '#FFFFFF';

    return (
      <>
        <SectionStyles
          backgroundColor={backgroundColor}
          paddingTop={padding}
          className="mt-5 mt-lg-0"
        >
          <div className="container">
            <div className="row list-wrapper">
              {data.floatingheader ? (
                <div className="col-sm-12">
                  <div className="sticky-top floating-header">
                    <SectionHeader
                      className="p-0"
                      title={data.title}
                      body={data.body}
                      link={data.link}
                      textAlign={data.textAlign}
                      bottomMargin="16px"
                      fullWidth
                    />
                  </div>
                  <div className="floating-body">
                    {content.map((val) => (
                      <List data={val} key={pmUuid()} />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="col-sm-12 col-md-8 offset-md-2">
                  <div className="row">
                    <SectionHeader
                      title={data.title}
                      body={data.body}
                      link={data.link}
                      textAlign={data.textAlign}
                      bottomMargin="16px"
                    />
                  </div>
                  <div className="row">
                    {content.map((val) => (
                      <List data={val} key={pmUuid()} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </SectionStyles>
        {(data.divider === true && <Divider />) || null}
      </>
    );
  }
}

RichList.propTypes = {
  data: PropTypes.shape(dataShape).isRequired
};

function ListRich(data) {
  return <RichList data={data} />;
}

export default ListRich;
