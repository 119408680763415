import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  .screenshot {
    background-color: #f9f9f9;
    position: relative;
    display: none;
    left: 0;
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};

    @media (min-width: 576px) {
      display: block;
      height: 800px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: 24px;
    }
  }
`;

function ScreenshotContainer() {
  return (
    <ImageContainer>
      <img
        width="1280"
        className="screenshot"
        src="https://voyager.postman.com/screen/postman-product-screen-05-2023.svg"
        alt="Screen grab of the Postman API Platform interface."
      />
    </ImageContainer>
  );
}

export default ScreenshotContainer;
