import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../Theme';
import BaseLink from './links/BaseLink';
// import { BaseLinkStyles } from "../links/BaseLinkStyles.jsx";

const FeatureWrapper = styled.div`
  margin-bottom: 5rem;
`;

const ContentContainer = styled.div`
  a {
    color: ${theme.colors.blue_60};
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.blue_60};
      cursor: pointer;
      transition: ${theme.transitions.all};
    }
  }
  ul {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 2rem;
    list-style-type: none;

    li {
      padding-left: 3.3rem;
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 2.4rem;
      position: relative;
      display: list-item;
      list-style-type: none;
      background-image: url(https://voyager.postman.com/icon/starburst-four-point.svg);
      background-position: 0 0.8rem;
      background-size: 1rem 1rem;
      background-repeat: no-repeat;

      ul,
      ol {
        margin-top: 2.4rem;
      }
      ul {
        padding-left: 0px;
      }
      ol {
        padding-left: 0.9rem;
        li {
          list-style-type: decimal !important;
          padding-left: 1rem !important;
          background-image: none;
        }
      }
    }
    &.checkmark li {
      background-image: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
      background-size: 1.3rem 1.3rem;
      ol {
        li {
          list-style-type: decimal !important;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  ol {
    margin-bottom: 2.4rem;
    margin-left: 0px;
    padding-left: 3.2rem;

    li {
      color: ${theme.colors.grey_90};
      line-height: 1.5;
      margin-bottom: 1.2rem;
      position: relative;
      display: list-item;

      padding-left: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.4rem;

      &:before,
      &:after {
        display: inline-block;
        text-align: right;
      }

      &:before {
        direction: rtl;
        margin-left: -2.8rem;
        padding-right: 1.6rem;
        width: 2.8rem;
      }
      ol,
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
      }
      ul {
        margin-top: 2.4rem;
        padding-left: 0px;
        & li {
          padding-left: 3.3rem;
          margin-left: 0px;
        }
      }
    }

    li::marker {
      color: ${theme.colors.grey_70} !important;
    }

    li > ol {
      list-style: lower-alpha;
    }
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 3rem;
  &.logo {
    height: 80px;
  }
  &.thumbnail {
    height: 19rem;
  }
  &.spot {
    height: 12rem;
  }
  &.icon {
    height: 5rem;
  }
`;

function FeatureCard({ imageSize, title, subtitle, colWidth, media, link }) {
  const linkData = link || '';
  if (linkData) {
    linkData.alternative = false;
  }

  return (
    <FeatureWrapper
      className={`${colWidth} pr-md-5 flex-column justify-content-between`}
    >
      <div>
        {media && imageSize !== null && (
          <ImageWrapper
            className={imageSize}
            style={{
              backgroundImage: `url("${media.src}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center left'
            }}
          />
        )}
        {title && <h3 className="h4 card-title mb-4">{title}</h3>}
        {subtitle && (
          <ContentContainer
            dangerouslySetInnerHTML={{ __html: subtitle.join('') }}
          />
        )}
      </div>
      {link && (
        <div>
          <BaseLink
            linkType="arrowLink"
            src={linkData.src}
            target={linkData.target ? linkData.target : 'new-tab-postman'}
          >
            {linkData.text}
          </BaseLink>
        </div>
      )}
    </FeatureWrapper>
  );
}

FeatureCard.propTypes = {
  link: PropTypes.shape({}),
  media: PropTypes.shape({
    src: PropTypes.string
  }),
  title: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  colWidth: PropTypes.string,
  imageSize: PropTypes.string
};

FeatureCard.defaultProps = {
  link: null,
  media: null,
  title: null,
  subtitle: null,
  colWidth: 'col-lg-6 col-xl-4',
  imageSize: 'icon'
};

export default FeatureCard;
